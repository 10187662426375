<template>
  <section id="company" class="vbox">
    <Navigation></Navigation>
    <section class="wrapper" style="margin-top: 30px;">
      <router-link
        :to="{
          path: '/companies/' + company.url + '/teams/new',
          query: {
            companyId: company.id,
            companyName: company.name,
            companyUrl: company.url,
            archived: company.archived,
          },
        }"
        class="btn btn-s-md btn-success pull-right"

      >
        <i class="fa fa-plus"></i> Create team
      </router-link>
      <a
        @click="archiveCompany"
        href="#"
        class="btn btn-s-md btn-secondary pull-right"
        >{{ company.archived ? 'Un-Archive Company' : 'Archive Company' }}</a
      >

      <h3>{{ company.name }}{{ company.archived ? ' (Archived)' : '' }}</h3>

      <div class="team-section">
        <h4>Active Teams</h4>
        <ul class="list-group m-b-none list-group-lg list-group-sp">
          <li
            v-for="team in activeTeams"
            :key="team.id"
            class="list-group-item"
          >
            <router-link
              :to="`/companies/${company.url}/teams/${team.url}`"
              class="clear"
            >
              <code class="pull-right">/{{ team.url }}</code>
              <a
                @click="archiveTeam({ e, team })"
                href="#"
                class="btn btn-s-md btn-secondary pull-right"
                >Archive Team</a
              >
              <strong class="block"
                >{{ team.name }}{{ team.archived ? ' (Archived)' : '' }}</strong
              >
            </router-link>
          </li>
        </ul>
      </div>

      <div class="team-section">
        <h4>Archived Teams</h4>
        <ul class="list-group m-b-none list-group-lg list-group-sp">
          <li
            v-for="team in archivedTeams"
            :key="team.id"
            class="list-group-item"
          >
            <router-link
              :to="`/companies/${company.url}/teams/${team.url}`"
              class="clear"
            >
              <code class="pull-right">/{{ team.url }}</code>
              <a
                @click="archiveTeam({ e, team })"
                href="#"
                class="btn btn-s-md btn-secondary pull-right"
                >Un-Archive Team</a
              >
              <strong class="block"
                >{{ team.name }}{{ team.archived ? ' (Archived)' : '' }}</strong
              >
            </router-link>
          </li>
        </ul>
      </div>
    </section>
  </section>
</template>

<script>
  import { ref, onMounted, computed, defineComponent } from 'vue'
  import firebase from 'firebase/compat/app'
  import 'firebase/compat/auth'
  import 'firebase/compat/firestore'
  import { useRoute } from 'vue-router'
  import Navigation from '@/components/Navigation'

  export default defineComponent({
    name: 'Company',
    components: {
      Navigation
    },

    setup() {
      const company = ref({})
      const teams = ref([])
      const route = useRoute()

      const getTeams = () => {
        firebase
          .firestore()
          .collection('teams')
          .where('companyId', '==', company.value.id)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              teams.value.push({
                id: doc.id,
                ...doc.data(),
              })
            })
          })
      }

      onMounted(() => {
        firebase
          .firestore()
          .collection('companies')
          .where('url', '==', route.params.id)
          .limit(1)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              company.value = {
                id: doc.id,
                ...doc.data(),
              }
              getTeams()
            })
          })
      })

      const activeTeams = computed(() =>
        teams.value.filter((team) => !team.archived)
      )
      const archivedTeams = computed(() =>
        teams.value.filter((team) => team.archived)
      )

      const archiveCompany = (e) => {
        e.preventDefault()

        company.value.archived = !company.value.archived

        if (company.value.archived === true) {
          teams.value.forEach((team) => {
            team.archived = true
          })
        }

        firebase.firestore().collection('companies').doc(company.value.id).set(
          {
            archived: company.value.archived,
          },
          {
            merge: true,
          }
        )
      }

      const archiveTeam = ({ e, team }) => {
        e.preventDefault()

        team.archived = !team.archived

        firebase.firestore().collection('teams').doc(team.id).set(
          {
            archived: team.archived,
          },
          {
            merge: true,
          }
        )
      }

      return {
        company,
        activeTeams,
        archivedTeams,
        archiveCompany,
        archiveTeam
      }
    }
  })
</script>

<style scoped>
  .team-section {
    margin: 40px 0 0;
  }
</style>
