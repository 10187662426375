<template>
  <el-header class="header">
    <div class="logo-container">
      <Logo :logo="require('../assets/logo.png')" />
    </div>
    <div class="menu-container">
      <div class="desktop">
        <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
          background-color="#594f8d"
          text-color="#fff"
        >
          <el-sub-menu>
            <template #title>{{email}}</template>
            <el-menu-item
              @click="logout"
            >
              logout
            </el-menu-item>
          </el-sub-menu>
        </el-menu>
      </div>
      <div class="mobile">
        <el-icon class="fold-icon">
          <fold @click="drawer = true" />
        </el-icon>
      </div>
    </div>
  </el-header>
        
  <el-drawer
    v-model="drawer"
    direction="rtl"
    size="60%"
    :before-close="handleClose"
    :with-header="false"
  >
    <el-menu
      :default-active="activeIndex"
      :default-openeds="['1']"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      @select="handleSelect"
    >
      <el-menu-item
        @click="logout"
      >
        logout
      </el-menu-item>
    </el-menu>
  </el-drawer>
</template>

<script>
  import { defineComponent, ref, onBeforeMount } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { Fold } from '@element-plus/icons'
  import Logo from '@/components/Logo'
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import 'firebase/compat/firestore';

  export default defineComponent({
    name: 'Navigation',
    components: {
      Fold,
      Logo
    },

    setup() {
      const route = useRoute()
      const router = useRouter()
      const email = ref('')

      const drawer = ref(false)
      const activeIndex = ref(window.location.pathname)

      onBeforeMount(() =>{
        email.value = firebase.auth().currentUser?.email
      })
      const goToPath = (path) => {
        if (route.path !== path) {
          router.push({ path })
        }
      }

      const handleClose = (done) => {
        if (typeof done === 'function') {
          done()
        }
      }

      const handleOpen = () => {}

      const handleSelect = (index) => {
        if (index === '4') {
          drawer.value = true
        } else {
          drawer.value = false
        }
      }

      const logout = () => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            router.push('login')
          })
      }

      return {
        drawer,
        activeIndex,
        email,
        logout,
        goToPath,
        handleClose,
        handleOpen,
        handleSelect,
      }
    },
  })
</script>


<style>
  body,
  html {
    margin: 0;
  }

  .el-drawer__body {
    padding: 0 !important;
    text-align: left;
  }

  .el-drawer__body .el-menu-item.dock-right {
    padding: 0 !important;
    text-align: center;
  }
</style>
<style scoped>
  .header {
    height: 64px;
    padding: 0;
    border-bottom: 1px solid #e6e6e6;
    background-color: #594f8d;
    margin-bottom: 50px;
  }

  .main {
    height: calc(100vh - 180px);
    padding: 6px;
  }


  .logo-container {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 60px;
    line-height: 56px;
    margin: 0;
    padding: 0 20px;
    list-style: none;
    cursor: pointer;
    box-sizing: border-box;
    white-space: nowrap;
    float: left;
    background-color: #594f8d;
  }

  .logo-container * {
    vertical-align: middle;
  }

  .menu-container {
    width: calc(100% - 210px);
    height: 100%;
    float: right;
    display: flex;
  }

  .menu-container .el-menu {
    width: 100%;
    justify-content: right;
  }

  .menu-container .el-menu--horizontal {
    border-bottom: 0;
  }

  .fold-icon {
    width: 2em;
    height: 2em;
    vertical-align: middle;
  }

  .fold-icon svg {
    width: 2em;
    height: 2em;
  }

  .desktop {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .mobile {
    float: right;
    display: none;
  }

  .wallet-container {
    margin: 0 40px;
  }

  .wallet-mobile-container {
    width: 100%;
  }

  .s1-social-links img {
    width: 25px;
    height: auto;
  }

  .s1-social-links a {
    margin: 10px 5px 10px 15px;
  }

  .s1-social-links {
    margin-top: 10px;
  }

  .mobile-menu-item {
    font-weight: bold;
  }

  /* Specific styles for Mobile Devices */
  @media screen and (max-width: 575px) {
    .logo-container {
      width: 80px;
    }

    .menu-container {
      width: 45px;
    }

    .main {
      padding: 6px;
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
