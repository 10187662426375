<template>
  <section id="survey" class="vbox">
    <div v-show="loading" class="text-center wrapper">
      <i class="fa fa-spinner fa fa-spin fa fa-large"></i>
    </div>

    <section
      v-if="team.archived || company.archived"
      v-show="!loading"
      id="thank-you"
      class="m-t-lg wrapper-md animated fadeInUp"
    >
      <div class="panel-body text-center">
        <img src="~@/assets/logoDark.png" width="300px" />
      </div>
      <div class="row m-n text-center">
        <p class="h2" style="margin: 10px 0">
          This survey is no longer available.
        </p>
      </div>
    </section>

    <section v-else class="wrapper" v-show="!loading">
      <div class="panel-body text-center">
        <img src="~@/assets/logoDark.png" width="240px" />
      </div>
      <section class="panel container">
        <div class="panel-body col-md-10 col-md-offset-1">
          <h2>My DecisionStyle Profile</h2>
          <p>
            Please take a moment to complete the following questions to discover
            your most natural source of strength and influence as a leader in
            decision making, and what tends to get in your way. You will be
            asked to complete one page worth of questions and total response
            time should be approximately 5 minutes.
          </p>
          <p>
            Your scores will be tabulated and displayed to you immediately
            following your completion of the survey and a copy will be emailed
            to the address you provide.
          </p>
        </div>
      </section>
      <Form @submit="onSubmit" :validation-schema="validationSchema" ref="surveyFormRef" v-slot="{ errors }">
        <section class="panel container">
          <div class="panel-body col-md-10 col-md-offset-1">
            <div class="row well">
              <h4>
                Enter your name below
                <em class="heading-subtitle"
                  >The survey system will include your name in your report</em
                >
              </h4>
              <div
                class="form-group col-sm-6"
                :class="{'has-error': errors.firstName}"
              >
                <label class="control-label">First Name</label>
                <Field name="firstName" type="text" placeholder="Jane" class="form-control" />
                <ErrorMessage name="firstName" class="text-danger" />
              </div>
              <div
                class="form-group col-sm-6"
                :class="{'has-error': errors.lastName}"
              >
                <label class="control-label">Last Name</label>
                <Field name="lastName" type="text" placeholder="Doe" class="form-control" />
                <ErrorMessage name="lastName" class="text-danger" />
              </div>
              <div class="clearfix">&nbsp;</div>
              <h4>
                Enter your email address below
                <em class="heading-subtitle">
                  The survey system will email a copy of your results to the
                  address provided
                </em>
              </h4>
              <div
                class="form-group col-sm-6"
                :class="{'has-error': errors.email}"
              >
                <label class="control-label">Email</label>
                <Field name="email" type="email" placeholder="user@example.com" class="form-control" />
                <ErrorMessage name="email" class="text-danger" />
              </div>
              <div
                class="form-group col-sm-6"
                :class="{'has-error': errors.emailConfirmation}"
              >
                <label class="control-label">Email Confirmation</label>
                <Field name="emailConfirmation" type="email"  placeholder="user@example.com" class="form-control" />
                <ErrorMessage name="emailConfirmation" class="text-danger"/>
              </div>
            </div>
          </div>
        </section>
        <section class="panel container">
          <div class="row">
            <header class="panel-body col-md-10 col-md-offset-1">
              <p>
                Experienced leaders at your level are able to do many things
                well when leading decision making, but these do not always
                correspond to your natural or preferred way. In your role as a
                leader, when you are faced with a critical decision or an
                important new challenge, please indicate how often your
                <strong>natural, preferred or immediate response</strong> is to:
              </p>
            </header>
            <div class="panel-body">
              <div class="survey-wrapper col-md-10 col-md-offset-1">
                <FieldArray name="answers" v-slot="{ fields }">
                  <fieldset
                    class="InputGroup survey-question"
                    v-for="(field, idx) in fields"
                    :key="field.key"
                  >
                    <div class="survey-error-msg" :class="{'has-error': errors[`answers[${idx}].value`]}">
                      <p class="control-label">
                        {{ getQuestionText(idx) }}
                      </p>
                    </div>
                    <div class="survey-response">
                      <div class="btn-group btn-group-justified" role="group">
                        <label :for="`question-${idx}-n2`" class="btn">
                          <Field :id="`question-${idx}-n2`" :name="`answers[${idx}].value`" type="radio" value="-2" />
                          Never
                        </label>
                        <label :for="`question-${idx}-0`" class="btn">
                          <Field :id="`question-${idx}-0`" :name="`answers[${idx}].value`" type="radio" value="0" />
                          Rarely
                        </label>
                        <label :for="`question-${idx}-2`" class="btn">
                          <Field :id="`question-${idx}-2`" :name="`answers[${idx}].value`" type="radio" value="2" />
                          Sometimes
                        </label>
                        <label :for="`question-${idx}-4`" class="btn">
                          <Field :id="`question-${idx}-4`" :name="`answers[${idx}].value`" type="radio" value="4" />
                          Often
                        </label>
                        <label :for="`question-${idx}-6`" class="btn">
                          <Field :id="`question-${idx}-6`" :name="`answers[${idx}].value`" type="radio" value="6" />
                          Always
                        </label>
                      </div>
                      <ErrorMessage :name="`answers[${idx}].value`" class="text-danger" />
                    </div>
                  </fieldset>
                </FieldArray>
              </div>
            </div>
          </div>
          <footer class="clearfix">
            <div class="col-md-10 col-md-offset-1">
              <p>
                By clicking &quot;Submit Survey,&quot; your survey answers will
                be recorded. If you would like to review your answers, please do
                so now.
              </p>
              <div
                class="alert alert-danger"
                role="alert"
                v-show="Object.keys(errors).length > 0"
              >
                <strong>Error!</strong> Please correct all errors.
              </div>
              <div class="text-right">
                <router-link
                  :to="'/companies/' + this.$route.params.id"
                  class="btn btn-s-xs"
                  >Cancel</router-link
                >
                <button type="submit" class="btn btn-success btn-lg btn-s-xs">
                  Submit
                </button>
              </div>
            </div>
          </footer>
        </section>
      </Form>
    </section>
  </section>
</template>

<script>
  import shared from '@/shared.js'
  import { useRouter, useRoute } from 'vue-router'
  import { Field, Form, ErrorMessage, FieldArray, configure, useForm } from 'vee-validate'
  import * as yup from 'yup'
  import { ref, onMounted, defineComponent } from 'vue'
  
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import 'firebase/compat/firestore';

  export default defineComponent({
  name: 'Survey',
  components: {
    Form,
    Field,
    ErrorMessage,
    FieldArray
  },
  setup() {
    configure({
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: true,
      validateOnModelUpdate: true,
    });

    const validationSchema = yup.object({
      firstName: yup.string().min(2).required().label('First Name'),
      lastName: yup.string().min(2).required().label('Last Name'),
      email: yup.string().email().required().label('Email'),
      emailConfirmation: yup.string().email().required()
        .oneOf([yup.ref('email')], 'Email confirmation must match email')
        .label('Email Confirmation'),
      answers: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string().required().label('Answer'),
          })
        )
        .strict(),
    })

    const router = useRouter()
    const route = useRoute()
    const company = ref({})
    const team = ref({})
    const loading = ref(true)
    const questions = ref([])
    const answersRef = ref([])
    const surveyFormRef = ref(null)

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
    }

    onMounted(async () => {
      await getCompany()

      await getTeam()

      getQuestions()
    })

    const onSubmit = (values) => {
      if(values) {
        submitSurvey(values)
      }
    }

    const getTeam = async () => {
      const snapshot = await firebase
        .firestore()
        .collection('teams')
        .where('companyId', '==', company.value.id)
        .where('url', '==', route.params.id.toLowerCase())
        .limit(1)
        .get();
        
      if (snapshot.empty) {
        return;
      }

      const doc = snapshot.docs[0];
      team.value = {
        id: doc.id,
        ...doc.data()
      };
    }

    const getQuestions = () => {
      firebase
        .firestore()
        .collection('questions')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            questions.value.push({
              id: doc.id,
              ...doc.data(),
            })
            shuffleArray(questions.value)
            answersRef.value.push({
              questionId: doc.id,
              value: ""
            })
            loading.value = false
          })
          surveyFormRef.value.setFieldValue('answers', answersRef.value)
        })
    }

    const getCompany = async () => {
      const snapshot = await firebase
        .firestore()
        .collection('companies')
        .where('url', '==', route.params.companyUrl.toLowerCase())
        .limit(1)
        .get();

      if (snapshot.empty) {
        return;
      }

      const doc = snapshot.docs[0];
      company.value = {
        id: doc.id,
        ...doc.data()
      };
    };

    const submitSurvey = (values) => {
      const survey = {
        teamId: team.value.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        answers: values.answers,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      }
      firebase
        .firestore()
        .collection('surveys')
        .add(survey)
        .then((docRef) => {
          const newSurveyId = docRef.id;
          router.replace(`/thank-you?auto=${team.value.autoEmail}&surveyId=${newSurveyId}`);
        })
        .catch((error) => {
          console.error("Error adding survey: ", error);
        });
    }

    const getQuestionText = (idx) => {
      const questionId = answersRef.value[idx].questionId;
      const question = questions.value.find((q) => q.id === questionId);
      return question ? question.text : '';
    };
    return {
      validationSchema,
      company,
      team,
      surveyFormRef,
      loading,
      questions,
      onSubmit,
      getQuestionText
    }
  }
})
</script>

<style>
  .text-danger {
    color: #a94442 !important;
    font-weight: bold !important;
    font-size: 12px !important;
  }
</style>
