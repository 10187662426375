<template>
  <div>
    <div class="text-center wrapper">
      <i class="fa fa-spinner fa fa-spin fa fa-large"></i>
    </div>

    <div class="pdfViewer">
      <iframe :src="pdfUrl"></iframe>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, defineComponent } from 'vue'
  import axios from 'axios'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    name: 'IndividualPDF',
    setup() {
      const route = useRoute()
      const router = useRouter()
      const pdfUrl = ref('')

      onMounted(async () => {
        const headers = {
          authorization: localStorage.getItem('Auth'),
        }

        try {
          const response = await axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL + `/reports/${route.params.id}`, {
            headers,
            responseType: 'blob',
          })
          pdfUrl.value = window.URL.createObjectURL(response.data)
        } catch (error) {
          console.log('Individual PDF Error:', error)
          router.push('/')
        }
      })
      
    return {
      pdfUrl
    }
  }
})
</script>

<style module scoped></style>
