<template>
  <section id="report" class="container">
    <div v-if="loading" class="text-center wrapper">
      <i class="fa fa-spinner fa fa-spin fa fa-large"></i>
    </div>
    <section v-else>
      <div class="panel">
        <div class="panel-body bg-primary">
          <img src="~@/assets/logo.png" class="pull-right" width="240px" />
          <div class="h2">DecisionStyles</div>
        </div>
        <section class="report-hero">
          <div class="col-md-10 col-md-offset-1 report-intro text-center">
            <h1 class="text-primary">
              Thank you
              <b class="text-primary"
                >{{ survey.firstName }} {{ survey.lastName }}</b
              >
              for taking the DecisionStyles Assessment!
            </h1>
          </div>
          <div class="col-md-8 col-md-offset-2 ds-box">
            <div class="panel-body text-center bg-light">
              <div v-if="hasATie" class="tie-callout">
                <h1>You Have a Tie!</h1>
                <span
                  >*A tie indicates that those styles show up equally. Please
                  see which of your styles tied below.</span
                >
              </div>
              <h3>Your primary DecisionStyle is:</h3>
              <div>
                <img :src="getImage(results[0].category)" width="120px" />
                <span class="h1">{{ results[0].displayName }}</span>
              </div>
            </div>
          </div>
        </section>
        <div class="panel-body">
          <div class="col-sm-12">
            <h3 class="text-center">
              Your preferred order of DecisionStyles is:
            </h3>
            <ul
              class="
                list-group
                no-radius
                m-b-none m-t-n-xxs
                list-group-lg
                ds-list
              "
            >
            <li v-for="(result, index) in results" :key="result.category" class="list-group-item col-md-6 col-md-offset-3">
              <div class="ds-tile row">
                <div class="first-tie-flag" v-if="result.firstTie"></div>
                <div class="tie-flag" v-if="result.tie"></div>
                <div class="col-xs-12 col-sm-4 col-md-3">
                  <img :src="getImage(result.category)" class="thumb-lg" />
                </div>
                <div class="col-xs-12 col-sm-8 col-md-9 result-tile">
                  <span class="h3">{{ index + 1 }}</span>
                  <span class="h3">{{ result.displayName }}</span>
                </div>
              </div>
            </li>
            </ul>
          </div>
        </div>
        <div class="panel-body about-ds">
          <div class="col-lg-10 col-lg-offset-1">
            <header>
              <h3 class="text-primary">About DecisionStyles</h3>
              <p>
                Our natural <strong>Decision styles</strong> reflect who we are
                on our best day. Knowing where that natural source of strength
                lies helps us recover more quickly when we get pulled off course
                and have more days be our best ones.
              </p>
            </header>
            <ul class="list-group list-group-lg no-border">
              <li
                v-for="description in descriptions"
                :key="description.category"
                class="list-group-item clear"
              >
                <div class="pull-left m-r-sm">
                  <img :src="getImage(description.category)" width="80px" />
                </div>
                <div class="clear">
                  <h3>{{ description.title }}</h3>
                  <p class="block">
                    <strong>{{ description.tags }}</strong>
                    {{ description.description }}
                  </p>
                  <p class="block">
                    <strong>Leadership impact on others:</strong>
                    {{ description.impact }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
  import { ref, onMounted, defineComponent } from 'vue'
  import shared from '@/shared.js'
  import { useRouter } from 'vue-router'
  
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import 'firebase/compat/firestore';

  export default defineComponent({
  name: 'Team',
  setup() {
    const router = useRouter()
    const loading = ref(true)
    const hasATie = ref(false)
    const team = ref({})
    const survey = ref({})
    const results = ref([])
    const descriptions = ref([
      {
        category: 'creativity',
        title: 'Creativity',
        tags: 'Possibility, Hope:',
        description:
          'You often speak in terms of future possibilities and have faith in the power of creativity and innovation. You tend to favor alternatives that feature unprecedented ideas you find personally inspiring.',
        impact: 'Faith and possibility.',
      },
      {
        category: 'learning',
        title: 'Learning',
        tags: 'Applied Learning:',
        description:
          'You are reliable for understanding and learning from experience. You tend to favor alternatives that feature important lessons from the past.',
        impact:
          'Compassion, appreciation for universal principles, and interest in improvement.',
      },
      {
        category: 'relationship',
        title: 'Relationship',
        tags: 'Acceptance, Compassion:',
        description:
          'You are sensitive to the effect of the decision on others. You respect different points of view and instinctively identify and include stakeholders in the process. You tend to favor alternatives that serve common purpose and will be well-received.',
        impact: 'Respect, interest, and appreciation.',
      },
      {
        category: 'workability',
        title: 'Workability',
        tags: 'Efficient, Effective Design:',
        description:
          'You focus on the practicality of various options and ease of execution. You tend to favor alternatives featuring tools, processes and well-designed plans that support ease of delivery.',
        impact:
          'Respect for the likelihood of effective execution through the use of timely, effective allocation of resources.',
      },
      {
        category: 'accountability',
        title: 'Accountability',
        tags: 'Bold, Congruent Action:',
        description:
          'You are focused on whether we have the skill and will necessary for making and keeping important promises. You tend to favor alternatives that feature specific people with relevant skills who have a history of delivering big commitments.',
        impact: 'Alignment of commitment and action.',
      },
    ])
  
    const orderedDescriptions = (currentResults, descriptions) => {
      const ordered = []
  
      currentResults.forEach((result) => {
        ordered.push(descriptions.find((e) => e.category === result.category))
      })
  
      return ordered
    }
  
    const getTeam = () => {
      firebase
        .firestore()
        .collection('teams')
        .doc(survey.value.teamId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            team.value = {
              id: doc.id,
              ...doc.data(),
            }
          }
          getQuestions()
        })
    }
  
    const getQuestions = () => {
      firebase
        .firestore()
        .collection('questions')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const surveyId = survey.value.answers.findIndex(
              (s) => s.questionId === doc.id
            )
            survey.value.answers[surveyId].question = doc.data()
          })
          getTotals()
        })
    }
  
    const getTotals = () => {
      results.value = shared.getResults(survey.value.answers)
      descriptions.value = orderedDescriptions(results.value, descriptions.value)
  
      const foundTie = results.value[0].tie
      if (foundTie) hasATie.value = true
  
      loading.value = false
    }
  
    const getImage = (category) => {
      return shared.getImage(category)
    }
  
    onMounted(() => {
      firebase
        .firestore()
        .collection('surveys')
        .doc(router.currentRoute.value.params.id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            survey.value = {
              id: doc.id,
              ...doc.data(),
            }
            getTeam()
          } else {
            router.push('/')
          }
        })
    })

    return{
      loading,
      team,
      survey,
      results,
      descriptions,
      getImage
    }
  }
})
</script>
