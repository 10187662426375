<template>
  <div>
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'app',
  }
</script>

<style>
.btn{
  margin: 0 5px !important;
}
</style>
