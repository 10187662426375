<template>
  <section id="new-company" class="vbox">
    <Navigation/>
    <section class="wrapper">
      <div class="row">
        <div class="col-sm-12">
          <form @submit="onSubmit">
            <section class="panel">
              <header class="panel-heading">
                <span class="h4">New Company</span>
              </header>
              <div class="panel-body">
                <div
                  class="form-group"
                  :class="{'has-error': errors.name}"
                >
                  <label class="control-label">Company Name</label>
                  <input
                    name="name"
                    v-model="name"
                    v-bind="nameAttrs"
                    type="text"
                    placeholder="Conversant"
                    class="form-control"
                  />
                  <span class="help-block">{{errors.name}}</span>
                </div>
              </div>
              <footer class="panel-footer text-right bg-light lter">
                <router-link to="/" class="btn btn-default btn-s-xs"
                  >Cancel</router-link
                >
                <button type="submit" class="btn btn-success btn-s-xs">
                  Submit
                </button>
              </footer>
            </section>
          </form>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
  import { useRouter } from 'vue-router'
  import firebase from 'firebase/compat/app'
  import 'firebase/compat/auth'
  import 'firebase/compat/firestore'
  import slug from 'slug'
  import { useForm } from 'vee-validate'
  import * as yup from 'yup'
  import { defineComponent } from 'vue'
  import Navigation from '@/components/Navigation'

  export default defineComponent({
    name: 'NewCompany',
    components: {
      Navigation
    },

    setup() {
      const router = useRouter()
      const { errors, handleSubmit, defineField } = useForm({
        validationSchema: yup.object({
          name: yup.string().min(2).required().label("Company Name"),
        }),
      })

      const [name, nameAttrs] = defineField('name')

      const onSubmit = handleSubmit((values) => {
        if (values) {
          createCompany()
        }
      })

      const createCompany = () => {
        firebase
          .firestore()
          .collection('companies')
          .add({
            name: name.value,
            url: slug(name.value.toLowerCase()),
            createdBy: firebase.auth().currentUser?.email,
            created: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            router.replace('/')
          })
      }

      return {
        name,
        nameAttrs,
        errors,
        onSubmit,
      }
    }
  })
</script>
