import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import reportSurveys from './reportSurveys'

export default createStore({
  modules: {
    reportSurveys,
  },
  plugins: [
    new VuexPersistence({
      modules: ['reportSurveys'],
    }).plugin,
  ],
})
