<template>
  <section id="new-team" class="vbox">
    <Navigation></Navigation>
    <section class="wrapper">
      <div class="row">
        <div class="col-sm-12">
          <form @submit="onSubmit">
            <section class="panel">
              <header class="panel-heading">
                <span class="h4">New Team for: {{ companyName }}</span>
              </header>
              <div class="panel-body">
                <div
                  class="form-group"
                  :class="{'has-error': errors.name}"
                >
                  <label class="control-label">Team Name</label>
                  <input
                    v-model="name"
                    v-bind="nameAttrs"
                    type="text"
                    placeholder="Developers"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.name
                  }}</span>
                </div>

                <div class="form-group">
                  <label class="control-label" style="display: block"
                    >Auto Emailing</label
                  >
                  <label>
                    <input
                      v-model="autoEmail"
                      v-bind="autoEmailAttrs"
                      type="checkbox"
                      class="form-control inline-checkbox"
                    />
                    <span class="inline-label"
                      >Email participants after survey completion</span
                    >
                  </label>
                </div>
              </div>
              <footer class="panel-footer text-right bg-light lter">
                <router-link
                  :to="'/companies/' + companyUrl"
                  class="btn btn-default btn-s-xs"
                  >Cancel</router-link
                >
                <button type="submit" class="btn btn-success btn-s-xs">
                  Submit
                </button>
              </footer>
            </section>
          </form>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
  import { defineComponent } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import 'firebase/compat/firestore';
  import slug from 'slug'
  import { useForm } from 'vee-validate'
  import * as yup from 'yup'
  import Navigation from '@/components/Navigation'

  export default defineComponent({
    name: 'NewTeam',
    components: {
      Navigation
    },

    setup() {
      const { errors, handleSubmit, defineField } = useForm({
        validationSchema: yup.object({
          name: yup.string().min(2).required().label("Team Name")
        }),
      })

      const [name, nameAttrs] = defineField('name')
      const [autoEmail, autoEmailAttrs] = defineField('autoEmail')

      const onSubmit = handleSubmit((values) => {
        if (values) {
          createTeam()
        }
      })

      const router = useRouter()
      const route = useRoute()
      const companyName = route.query.companyName
      const companyId = route.query.companyId
      const companyUrl = route.query.companyUrl
      const createTeam = () => {
        firebase
          .firestore()
          .collection('teams')
          .add({
            companyId: companyId,
            name: name.value,
            url: slug(name.value.toLowerCase()),
            createdBy: firebase.auth().currentUser?.email,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            autoEmail: autoEmail.value ? autoEmail.value : false,
            archived: route.query.archived == true ? true : false,
          })
          .then(() => {
            router.replace(`/companies/${route.params.id}`)
          })
      }

      return {
        name,
        nameAttrs,
        autoEmail,
        autoEmailAttrs,
        companyUrl,
        companyName,
        companyId,
        errors,
        onSubmit,
        createTeam
      }
    }
  })
</script>