import { createApp } from 'vue'
import App from './App'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import Notifications from '@kyvg/vue3-notification'
import './assets/css/bootstrap.css'
import './assets/css/font-awesome.min.css'
import './assets/css/landing.css'
import './assets/css/animate.css'
import './assets/css/plugin.css'
import './assets/css/app.css'
import './assets/css/style-extension.css'

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(Notifications)
  .mount('#app')
