import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const categories = [
  'creativity',
  'learning',
  'relationship',
  'workability',
  'accountability',
]

export default {
  categories,
  getImage(category) {
    switch (category) {
      case 'creativity':
        return require('./assets/creativity.png')
      case 'learning':
        return require('./assets/learning.png')
      case 'relationship':
        return require('./assets/relationship.png')
      case 'workability':
        return require('./assets/workability.png')
      default:
        return require('./assets/accountability.png')
    }
  },
  getResults(allAnswers) {
    const allResults = []
    categories.forEach((category) => {
      let total = 0
      let mean = 0

      const answers = allAnswers.filter((a) => a.question.category === category)
      answers.forEach((a) => {
        total += Number(a.value)
      })
      mean = (total / answers.length).toFixed(4)

      allResults.push({
        numAnswers: answers.length,
        category,
        displayName: category.charAt(0).toUpperCase() + category.slice(1),
        total,
        mean,
        tie: false,
        firstTie: false,
      })
    })

    allResults.sort((a, b) => {
      if (a.mean < b.mean) return 1
      if (a.mean > b.mean) return -1
      return 0
    })

    allResults.forEach((result, index) => {
      if (
        index > 0 &&
        result.mean.toString() === allResults[index - 1].mean.toString()
      ) {
        allResults[index - 1].tie = true
        allResults[index - 1].firstTie = true
        allResults[index].tie = true
      }
    })

    return allResults
  },

  getSurveys: async ({ id }) => {
    const teamId = id

    return new Promise((resolve) => {
      firebase
        .firestore()
        .collection('surveys')
        .where('teamId', '==', teamId)
        .get()
        .then((snapshot) => {
          const results = []
          snapshot.forEach((doc) => {
            results.push({
              id: doc.id,
              ...doc.data(),
            })
          })

          resolve(results)
        })
    })
  },

  getSurvey: async ({ id }) => {
    const surveyId = id

    return new Promise((resolve) => {
      firebase
        .firestore()
        .collection('surveys')
        .doc(surveyId)
        .get()
        .then((doc) => {
          if (doc) {
            resolve({
              id: doc.id,
              ...doc.data(),
            })
          } else {
            resolve(null)
          }
        })
    })
  },

  getQuestions: async ({ survey }) => { // eslint-disable-line
    return new Promise((resolve) => {
      firebase
        .firestore()
        .collection('questions')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const surveyId = survey.answers.findIndex(
              (s) => s.questionId === doc.id
            )
            survey.answers[surveyId].question = doc.data()
          })

          resolve(survey)
        })
    })
  },

  getBearerToken() {
    return localStorage.getItem('Auth')
  },

  setBearerToken({ token }) {
    localStorage.setItem('Auth', `Bearer ${token}`)
  },

  removeBearerToken() {
    localStorage.removeItem('Auth')
  },
}
