import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Home'
import ArchivedCompanies from '@/components/ArchivedCompanies'
import Login from '@/components/Login'
import ForgotPassword from '@/components/ForgotPassword'
import NewCompany from '@/components/NewCompany'
import Company from '@/components/Company'
import NewTeam from '@/components/NewTeam'
import Team from '@/components/Team'
import Survey from '@/components/Survey'
import Report from '@/components/Report'
import TeamReport from '@/components/TeamReport'
import ThankYou from '@/components/ThankYou'
import IndividualPDF from '@/components/IndividualPDF'
import TeamPDF from '@/components/TeamPDF'
import EditTeamReport from '@/components/EditTeamReport'
import { getAuth, onAuthStateChanged } from "firebase/auth";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/archived-companies',
    name: 'ArchivedCompanies',
    component: ArchivedCompanies,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/companies/new',
    name: 'NewCompany',
    component: NewCompany,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/companies/:id',
    name: 'Company',
    component: Company,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/companies/:id/teams/new',
    name: 'NewTeam',
    component: NewTeam,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/companies/:companyUrl/teams/:id',
    name: 'Team',
    component: Team,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reports/:id',
    name: 'Report',
    component: Report
  },
  {
    path: '/team-report/:teamId',
    name: 'TeamReport',
    component: TeamReport,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/individual-pdf/:id',
    name: 'IndividualPDF',
    component: IndividualPDF
  },
  {
    path: '/team-pdf/:id',
    name: 'TeamPDF',
    component: TeamPDF,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/edit-team-report/:teamId',
    name: 'EditTeamReport',
    component: EditTeamReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: ThankYou,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:companyUrl/:id',
    name: 'Survey',
    component: Survey,
    meta: {
      requiresAuth: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
}
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if(requiresAuth){
      if(user){
        user.getIdToken(false).then((token) =>{
          localStorage.setItem('Auth', `Bearer ${token}`)
          next()
        }).catch((error) =>{
          console.error('Error getting ID token:', error)
        })
      } else {
        localStorage.removeItem('Auth')
        next('/login')
      }
    } else {
      next()
    }
  });
})

export default router
