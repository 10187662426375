<template>
  <section id="team" class="vbox">
    <Navigation></Navigation>
    <section class="wrapper">
      <div v-show="loading" class="text-center wrapper">
        <i class="fa fa-spinner fa fa-spin fa fa-large"></i>
      </div>
      <section v-show="!loading">
        <div class="panel">
          <div class="panel-body">
            <div class="pull-right">
              <button type="button" @click="downloadCSV" class="btn btn-s-md btn-secondary">Download CSV</button>
              <button type="button" @click="archiveTeam" class="btn btn-s-md btn-secondary">{{ team.archived ? 'Un-Archive Team' : 'Archive Team' }}</button>
              <router-link class="btn btn-s-md btn-success" :to="{ path: '/' + company.url + '/' + team.url }">Survey</router-link>
              <router-link class="btn btn-s-md btn-primary" :to="{ path: '/edit-team-report/' + team.id }">Team Report</router-link>
            </div>
            <router-link :to="{ path: '/companies/' + company.url }">
              <h4>{{ company.name }}{{ company.archived ? ' (archived company)' : '' }}</h4>
            </router-link>
            <h5>{{ team.name }}{{ team.archived ? ' (archived team)' : '' }}</h5>
            <small>
              Survey URL:
              <code>{{'/' + company.url + '/' + team.url}}</code>
            </small>
          </div>
        </div>
        <h4>Surveys</h4>
        <ul class="list-group no-radius m-b-none m-t-n-xxs list-group-lg no-border">
          <li v-for="survey in surveys" :key="survey.id" class="list-group-item">
            <div class="clear">
              <div class="pull-right">
                <button @click="resendReport(survey)" class="btn btn-s-md btn-secondary" href="#">{{ survey.reportSent ? 'Report Sent!' : 'Resend Report' }}</button>
                <button @click="individualCSV(survey.id)" class="btn btn-s-md btn-info" href="#">CSV</button>
                <router-link class="btn btn-s-md btn-primary" :to="{ path: '/reports/' + survey.id }">Report</router-link>
                <router-link class="btn btn-s-md btn-primary" :to="{ path: '/individual-pdf/' + survey.id }">PDF</router-link>
              </div>
              <strong class="block">{{survey.firstName}} {{survey.lastName}}</strong>
              <small>{{survey.email}}</small>
              <div>
                <small class="smallest">{{survey.created.toDate().toLocaleString('en-US',{ year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'})}}</small>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { ref, onMounted, defineComponent } from 'vue';
import Navigation from '@/components/Navigation.vue';
import { useRoute } from 'vue-router'
import { getStorage, ref as refStorage, getDownloadURL } from "firebase/storage";

export default defineComponent({
  name: 'Team',
  components: {
    Navigation
  },
  setup() {
    const storage = getStorage();
    const route = useRoute()
    const loading = ref(true);
    const company = ref({});
    const team = ref({});
    const surveys = ref([]);
    const teamPdf = ref('');
    const allPdfs = ref([]);

    const getTeam = async () => {
      const snapshot = await firebase
        .firestore()
        .collection('companies')
        .where('url', '==', route.params.companyUrl)
        .limit(1)
        .get();
      snapshot.forEach(doc => {
        company.value = {
          id: doc.id,
          ...doc.data()
        };
      });
      const snapshot2 = await firebase
        .firestore()
        .collection('teams')
        .where('companyId', '==', company.value.id)
        .where('url', '==', route.params.id)
        .limit(1)
        .get();
      snapshot2.forEach(doc => {
        team.value = {
          id: doc.id,
          ...doc.data()
        };
        loading.value = false;
      });
      getSurveys();
      getTeamPdf();
    };

    const getSurveys = async () => {
      const snapshot = await firebase
        .firestore()
        .collection('surveys')
        .where('teamId', '==', team.value.id)
        .get();
      snapshot.forEach(doc => {
        surveys.value.push({
          id: doc.id,
          ...doc.data()
        });
        surveys.value.sort((a, b) => b.created - a.created);
        loading.value = false;
      });
    };

    const getTeamPdf = async () => {
      try{
        const url = await getDownloadURL(refStorage(storage, `team-reports/${team.value.id}.pdf`));
        teamPdf.value = url;
      } catch(e){
        console.log("Couldn't find file in storage")
      }
    };

    const getAllPdfs = async () => {
      try{
        surveys.value.forEach(async survey => {
          const url = await getDownloadURL(refStorage(storage, `reports/${survey.id}.pdf`));
          allPdfs.value[survey.id] = url;
        });
      } catch(e){
        console.log("Couldn't find files in storage")
      }
    };

    const resendReport = async (survey) => {
      survey.reportSent = true;
      setTimeout(() => {
        survey.reportSent = false
      }, 2500);

      const foundSurvey = surveys.value.find(survey => survey.id === survey.id);

      const headers = {
        authorization: localStorage.getItem('Auth'),
        'Content-Type': 'application/json'
      };

      await axios
        .post(
          process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL + '/resend-report',
          {
            surveyId: foundSurvey.id,
            email: foundSurvey.email,
            name: `${foundSurvey.firstName} ${foundSurvey.lastName}`
          },
          {
            headers
          }
        )
        .catch(error => {
          console.log('Resend report error : ', error);
        });
    };

    const downloadCSV = async () => {
      const headers = {
        authorization: localStorage.getItem('Auth'),
        'Content-Type': 'text/csv'
      };

      const response = await axios
        .get(process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL + `/team-csv/${team.value.id}`, {
          headers
        })
        .catch(error => {
          console.log('Team CSV error : ', error);
        });

      const csvBlob = new Blob([response.data], { type: 'text/csv' });
      const csvLink = document.createElement('a');

      csvLink.href = window.URL.createObjectURL(csvBlob, {
        type: 'text/plain'
      });
      csvLink.download = `${team.value.name}.csv`;
      document.body.appendChild(csvLink);
      csvLink.click();
      document.body.removeChild(csvLink);
    };

    const individualCSV = async (surveyId) => {
      const headers = {
        authorization: localStorage.getItem('Auth'),
        'Content-Type': 'text/csv'
      };

      const response = await axios
        .get(process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL + `/individual-csv/${surveyId}`, {
          headers
        })
        .catch(error => {
          console.log('Individual CSV error : ', error);
        });

      const csvBlob = new Blob([response.data], { type: 'text/csv' });
      const csvLink = document.createElement('a');
      const survey = surveys.value.find(s => s.id === surveyId);

      csvLink.href = window.URL.createObjectURL(csvBlob, {
        type: 'text/plain'
      });
      csvLink.download = `${survey.email}.csv`;
      document.body.appendChild(csvLink);
      csvLink.click();
      document.body.removeChild(csvLink);
    };

    const archiveTeam = async () => {
      team.value.archived = !team.value.archived;
      await firebase
        .firestore()
        .collection('teams')
        .doc(team.value.id)
        .set(
          {
            archived: team.value.archived
          },
          {
            merge: true
          }
        );
      
    };

    onMounted(getTeam);

    return {
      loading,
      company,
      team,
      surveys,
      teamPdf,
      allPdfs,
      getAllPdfs,
      resendReport,
      downloadCSV,
      individualCSV,
      archiveTeam
    };
  }
})
</script>

<style scoped>
</style>
