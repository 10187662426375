<template>
    <section id="team" class="vbox">
      <Navigation></Navigation>
      <section class="wrapper">
        <div v-show="loading" class="text-center wrapper">
          <i class="fa fa-spinner fa fa-spin fa fa-large"></i>
        </div>
        <section v-show="!loading">
          <h4 style="margin: 20px 0 30px 50px; font-weight: bold;">Please select the surveys to include in the Team Report PDF</h4>
          <ul class="list-group no-radius m-b-none m-t-n-xxs list-group-lg no-border">
            <li v-for="survey in surveys" :key="survey.id" class="list-group-item" style="margin: 15px 50px">
              <div class="pull-right" style="margin: 20px 10% 0 0;">
                <div class="selected-survey-marker" v-show="surveyIndex(survey.id) > 0">{{ surveyIndex(survey.id) }}</div>
              </div>  
              <div class="clear" @click="clickedSurvey(survey.id)">
                <strong class="block">{{survey.firstName}} {{survey.lastName}}</strong>
                <small>{{survey.email}}</small>
                <div>
                  <small class="smallest">{{survey.created.toDate().toLocaleString('en-US',{ year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'})}}</small>
                </div>
              </div>
            </li>
          </ul>
          <div style="margin: 20px 0px 0 50px !important; padding-bottom: 50px;" v-show="reportSurveysRef.length > 0">
            <button class="btn btn-s-md btn-primary" :to="{ path: '/team-report/' + teamId }" @click="navigateToTeamReport">Team Report</button>
            <a target="_blank" class="btn btn-s-md btn-primary" :href="'/team-pdf/' + teamId">PDF</a>
          </div>
        </section>
      </section>
    </section>
  </template>
  
  <script>
    import { ref, defineComponent, onBeforeMount } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import Navigation from '@/components/Navigation.vue';
    import firebase from 'firebase/compat/app';
    import 'firebase/compat/firestore';
    import { useStore } from 'vuex'
  
    export default defineComponent({
      name: 'EditTeamReport',
      components: {
        Navigation
      },
      setup() {
        const store = useStore()
        const {
          dispatch,
        } = store
        const router = useRouter()
        const route = useRoute()
        const teamId = ref(null)
        const loading = ref(true)
        const surveys = ref([])
        const reportSurveysRef = ref([])

        onBeforeMount(async() =>{
            teamId.value = route.params.teamId
            await getSurveys()
            loading.value = false
        })
        
        const getSurveys = async() => {
            const snapshot = await firebase
                .firestore()
                .collection('surveys')
                .where('teamId', '==', teamId.value)
                .get();
            snapshot.forEach(doc => {
                surveys.value.push({
                id: doc.id,
                ...doc.data()
                });
                surveys.value.sort((a, b) => b.created - a.created);
                loading.value = false;
            });
        }

        const clickedSurvey = (surveyId) => {
          const surveyIndex = reportSurveysRef.value.findIndex(item => item.surveyId === surveyId);
          
          if (surveyIndex !== -1) {
            reportSurveysRef.value.splice(surveyIndex, 1);
            reportSurveysRef.value.forEach(item => {
              if (item.index > surveyIndex) {
                item.index--;
              }
            });
          } else {
            const maxIndex = reportSurveysRef.value.length > 0 ? Math.max(...reportSurveysRef.value.map(item => item.index)) : -1;
            reportSurveysRef.value.push({ surveyId, index: maxIndex + 1 });
          }

          dispatch('setReportSurveys', reportSurveysRef.value)
        };

        const surveyIndex = (surveyId) => {
          return reportSurveysRef.value.find(item => item.surveyId === surveyId)?.index + 1
        }

        const navigateToTeamReport = () => {
          router.push({ name: 'TeamReport' });
        };

        return {
          teamId,
          surveys,
          reportSurveysRef,
          surveyIndex,
          clickedSurvey,
          navigateToTeamReport
        }
      }
    })
  </script>
  
  <style>
  .selected-survey-marker{
    border-radius: 100%;
    background-color: blueviolet;
    width: 50px;
    height: 50px;
    color: white;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  