<template>
  <section id="home" class="vbox home">
    <Navigation/>
    <section class="wrapper">
      <router-link
        to="/companies/new"
        class="btn btn-s-md btn-success pull-right"
      >
        <i class="fa fa-plus"></i> Create company
      </router-link>
      <h3>
        Archived Companies
        <router-link to="/" class="btn btn-secondary"
          >Active Companies</router-link
        >
      </h3>

      <ul class="list-group m-b-none list-group-lg list-group-sp">
        <li
          v-for="company in archivedCompanies"
          :key="company.key"
          class="list-group-item"
        >
          <router-link :to="'/companies/' + company.url" class="clear">
            <code class="pull-right">/{{ company.url }}</code>
            <strong class="block">{{ company.name }}</strong>
          </router-link>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
  import { ref, onMounted, computed, defineComponent } from 'vue'
  import firebase from 'firebase/compat/app'
  import 'firebase/compat/auth'
  import 'firebase/compat/firestore'
  import Navigation from '@/components/Navigation'

  export default defineComponent({
    name: 'ArchivedCompanies',
    components: {
      Navigation
    },
    setup() {
      const companies = ref([])

      onMounted(() => {
        firebase
          .firestore()
          .collection('companies')
          .orderBy('created', 'desc')
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              const data = doc.data()
              if (data.archived) {
                companies.value.push({
                  key: doc.id,
                  ...data,
                })
              }
            })
          })
      })
      const archivedCompanies = computed(() => companies.value)

      return {
        archivedCompanies
      }
    }
  })
</script>
