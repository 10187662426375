<template>
  <div>
    <div class="text-center wrapper">
      <i class="fa fa-spinner fa fa-spin fa fa-large"></i>
    </div>

    <div class="pdfViewer">
      <iframe :src="pdfUrl"></iframe>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { ref, onMounted, defineComponent } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'TeamPDF',
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()

      const reportSurveys = ref([])
      const pdfUrl = ref('')

      onMounted(async () => {
        reportSurveys.value = store.state.reportSurveys.reportSurveys

        const headers = {
          authorization: localStorage.getItem('Auth'),
        }

        try {
          const response = await axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL + `/team-report/${route.params.id}`, {reportSurveys: reportSurveys.value}, {
            headers,
            responseType: 'blob',
          });

          pdfUrl.value = window.URL.createObjectURL(response.data)
        } catch (error) {
          console.log('Team PDF Error : ', error)
          router.push('/')
        }
      })

      return{
        pdfUrl
      }
    }
  })
</script>
