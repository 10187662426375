<template>
  <section id="thank-you" class="m-t-lg wrapper-md animated fadeInUp">
    <div class="panel-body text-center">
      <img src="~@/assets/logoDark.png" width="300px" />
    </div>
    <div class="row m-n text-center">
      <i class="fa fa-smile-o h"></i>
      <p class="h2">Thank you for your response</p>
      <p class="h2" v-if="autoEmail">
        Your results will be emailed to you shortly.
      </p>
      <router-link class="btn thank-you-btn" :to="{ path: '/reports/' + surveyId }"> Review your results</router-link>
      <router-link class="btn thank-you-btn" :to="{ path: '/individual-pdf/' + surveyId }"> Download your report</router-link>
    </div>
  </section>
</template>

<script>
  import { computed, defineComponent } from 'vue'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    name: 'ThankYou',
    setup() {
      const route = useRoute()
      const autoEmail = computed(() => route.query.auto === 'true')
      const surveyId = computed(() => route.query.surveyId)

      return {
        autoEmail,
        surveyId
      }
    }
  })
</script>

<style>
.thank-you-btn{
  margin: 25px 10px 0px 10px !important;
  border-color: #3ca2f0 !important;
  border-radius: 5px !important;
  padding: 10px 5px !important;
  min-width: 200px !important;
}
</style>
