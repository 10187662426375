<template>
  <section id="login" class="m-t-lg wrapper-md animated fadeInUp">
    <div class="row m-n text-center">
      <router-link to="/">
        <img src="~@/assets/logoDark.png" width="300px" />
      </router-link>
    </div>
    <div class="row m-n">
      <div class="col-md-4 col-md-offset-4 m-t-lg">
        <section class="panel">
          <form class="panel-body" @submit="onSubmit">
            <div
              class="form-group"
              :class="{'has-error': errors.email}"
            >
              <label class="control-label">Email</label>
              <input
                name="email"
                v-model="email"
                v-bind="emailAttrs"
                type="text"
                placeholder="test@example.com"
                class="form-control"
              />
              <span class="help-block">{{
                errors.email
              }}</span>
            </div>
            <div
              class="form-group"
              :class="{'has-error': errors.password}"
            >
              <label class="control-label">Password</label>
              <input
                type="password"
                name="password"
                v-model="password"
                v-bind="passwordAttrs"
                placeholder="password"
                class="form-control"
              />
              <span class="help-block">{{
                errors.password
              }}</span>
            </div>
            <router-link to="/forgot-password" class="pull-right m-t-xs">
              <small>Forgot password?</small>
            </router-link>
            <button type="submit" class="btn btn-info">Sign in</button>
          </form>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
  import { auth } from '@/firebase'
  import { useForm } from 'vee-validate'
  import * as yup from 'yup'
  import { useRouter } from 'vue-router'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Login',
    setup() {
      const router = useRouter()
      const { errors, handleSubmit, defineField } = useForm({
        validationSchema: yup.object({
          email: yup.string().email().required().label("Email"),
          password: yup.string().min(6).required().label("Password"),
        }),
      })

      const [email, emailAttrs] = defineField('email')
      const [password, passwordAttrs] = defineField('password')

      const onSubmit = handleSubmit((values) => {
        if (values) {
          login()
        }
      })

      const login = () => {
        auth
          .signInWithEmailAndPassword(email.value, password.value)
          .then(() => {
            router.replace('/')
          })
          .catch((error) => {
            alert(error.message)
          })
      }

      return{
        email,
        emailAttrs,
        password,
        passwordAttrs,
        errors,
        onSubmit
      }
    }
  })
</script>

<style scoped></style>
