<template>
  <section class="container-fluid" id="team-report">
    <div v-if="loading" class="text-center">
      <i class="fa fa-spinner fa fa-spin fa fa-large"></i>
    </div>
    <section v-else>
      <div class="panel row">
        <div class="panel-body bg-primary">
          <img src="~@/assets/logo.png" class="pull-right" width="240px" />
          <div class="h2">DecisionStyles</div>
          <div class="h5">{{ team.name }}</div>
        </div>
        <div class>
          <div v-for="(person, index) in people" :key="person.survey.id">
            <div v-if="index % 8 === 0">
              <div class="clearfix">&nbsp;</div>
              <div class="person-col rank">
                <header class="no-well">
                  <h4>&nbsp;</h4>
                </header>
                <div class="text-center result-type" v-for="n in 5" :key="n">
                  <h2>{{ n }}</h2>
                </div>
              </div>
            </div>
            <div class="text-center person-col">
              <div class="well">
                <header>
                  <h4>
                    {{ person.survey.firstName }} {{ person.survey.lastName }}
                  </h4>
                </header>
                <div
                  class="result-type"
                  v-for="(result, index) in person.results"
                  :key="index"
                  :class="{ tie: result.tie, 'first-tie': result.firstTie }"
                >
                  <img :src="getImage(result.category)" width="80px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
  import { ref, onMounted, defineComponent } from 'vue'
  import { useRoute } from 'vue-router'
  import shared from './../shared'
  
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import 'firebase/compat/firestore';
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'TeamReport',
    setup() {
      const store = useStore()
      const route = useRoute()
      
      const reportSurveys = ref([])
      const loading = ref(true)
      const team = ref({})
      const people = ref([])
      const surveys = ref([])

      onMounted(async () => {
        reportSurveys.value = store.state.reportSurveys.reportSurveys
        firebase
          .firestore()
          .collection('teams')
          .doc(route.params.teamId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              team.value = {
                id: doc.id,
                ...doc.data(),
              }
            }
            getSurveys()
          })
      })

      const getSurveys = async() => {
        const sortedSurveys = reportSurveys.value.sort((a, b) => a.index - b.index);
        for(const survey of sortedSurveys){
          const docRef = firebase.firestore().collection('surveys').doc(survey.surveyId);
          const doc = await docRef.get();
          if (doc.exists) {
            surveys.value.push(doc.data());
          } else {
            console.log('Could not find a survey with this ID');
          }
        }
        getQuestions()
      }

      const getQuestions = () => {
        firebase
          .firestore()
          .collection('questions')
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              surveys.value.forEach((survey, index) => {
                const surveyId = surveys.value[index].answers.findIndex(
                  (s) => s.questionId === doc.id
                )
                surveys.value[index].answers[surveyId].question = doc.data()
              })
            })
            getTotals()
          })
      }

      const getTotals = () => {
        surveys.value.forEach((survey) => {
          people.value.push({
            survey,
            results: shared.getResults(survey.answers),
          })
        })

        loading.value = false
      }

      const getImage = (category) => {
        return shared.getImage(category)
      }

      return {
        loading,
        team,
        people,
        getImage
      }
    }
  })
</script>
